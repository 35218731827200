.search-card {
  cursor: pointer;
  margin: .25rem 0;
  padding: .25rem;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #3333331a;
  box-shadow: 2px 2px 5px -4px black;

  &:hover {
    filter: brightness(.9);
  }

  &.category .badge {
    font-size: 7pt;
  }

  .info {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: .5rem;
  }

  .title {
    font-size: 10pt;
    margin-bottom: 0;
    color: #343a40;
  }

  .price {
    line-height: 1;
    display: flex;
    flex-direction: row;

    .old-price .tag, .old-price .value {
      font-size: 10pt;
    }

    .updated-price {
      margin-left: auto;

      .value {
        font-size: 14pt;
      }
    }
  }
}
