#expand-booklet-modal {
  max-height: 90vh;
}

.booklet-container {
  max-width: unset;
}

.col-preview {
  background-color: #f7f7f7;
  max-height: 75vh;
  overflow-y: scroll;
}

.col-zoom {
  background-color: #fff;
  max-height: 75vh;
  overflow-y: scroll;

  .caption {
    word-break: break-word;
  }
}

.hover-zoom {
  width: 400px;
  height: 400px;
}

.col-preview {
  display: none !important;
}

.page-preview {
  .page-preview-item {
    cursor: pointer;
    height: 100px;
    width: 100px;
    overflow: hidden;
    border-radius: 4px;
    border: 1px solid #c7c7c7;
    background-color: #fff;

    &:first-of-type {
      margin-left: 0;
    }

    &.active {
      opacity: 0.5;
    }
    &:hover {
      filter: brightness(0.9);
    }
  }
}

.hover-zoom {
  &:last-child {
    margin-bottom: 10px;
  }
}

.booklet-wrapper {
  .caption {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
  }
}

.booklet-image {
  max-width: 100% !important;
}

@media (min-width: 576px) {
  #expand-booklet-modal {
    max-width: 90vw;
  }

  .hover-zoom {
    width: 400px;
    height: 400px;
  }

  .col-preview {
    display: inline !important;
  }

  .booklet-image {
    max-width: 90% !important;
  }
}

@media (min-width: 880px) {
  .page-preview .page-preview-item {
    height: 150px;
    width: 150px;
  }
}

@media (min-width: 992px) {
  #expand-booklet-modal {
    max-width: 80vw;
  }

  .hover-zoom {
    width: 500px;
    height: 500px;
  }
}

@media (min-width: 1200px) {
  #expand-booklet-modal {
    max-width: 70vw;
  }

  .hover-zoom {
    width: 600px;
    height: 600px;
  }
}
