// @charset 'UTF-8';
@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;

  src: url('../../assets/fonts/slick.295183786cd8a1389865.woff') format('woff');

  font-display: swap;
}

.image-group-section {
  .description-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    transition: all 0.3s;

    .background {
      padding: 0.5rem 0.25rem;
      background-color: rgba(0, 0, 0, 0.658);
    }

    .description {
      text-align: center;
      line-height: 1;
      font-size: 15pt;
      color: #fff;
      margin: 0;
    }
  }
}

.slick-slider {
  .slick-arrow {
    width: 20px;
    height: 20px;
    z-index: 2;
    position: absolute;
    border-radius: 50%;
    background-color: #fff !important;

    &.slick-prev {
      left: 0;

      &::before {
        font-family: 'slick' !important;

        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }

    &.slick-next {
      right: 0;

      &::before {
        font-family: 'slick' !important;

        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }

    &::before {
      position: absolute;
      top: -2px;
      left: -2px;
      opacity: 1;
      font-size: 25px;
      color: #353535;
      font-family: 'slick' !important;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }

  .slick-dots {
    bottom: -29px;

    li button {
      &::before {
        font-family: 'slick';
        font-size: 11px;

        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
  }

  &.image-groups {
    .slick-prev {
      left: 15px;
    }

    .slick-next {
      right: 15px;
    }

    img {
      transition: all 0.3s;

      &:hover,
      &.hover {
        transform: scale(1.05);

        ~ .description-wrapper {
          transform: scale(1.05);
        }
      }
    }
  }
}

.product-slider-container {
  .slick-list {
    padding: 0 !important;
  }
}

.product-slider-container .remove-margin-from-grid .product-card {
  margin: 0 !important;
}

.slick-prev {
  left: -50px;
}
[dir='rtl'] .slick-prev {
  right: -40px;
  left: auto;
}
.slick-prev::before {
  content: '←';
}
[dir='rtl'] .slick-prev::before {
  content: '→';
}

.slick-next {
  right: -30px;
}
[dir='rtl'] .slick-next {
  right: auto;
  left: -25px;
}
.slick-next::before {
  content: '→';
}
[dir='rtl'] .slick-next::before {
  content: '←';
}

@media (min-width: 768px) {
  .slick-slider {
    .slick-arrow {
      &::before {
        top: -3px;
        left: -3px;
        font-size: 30px;
      }
    }
  }
}

@media (min-width: 1024px) {
  .slick-slider {
    .slick-arrow {
      width: 26px;
      height: 26px;

      &::before {
        top: -4px;
        left: -4px;
        font-size: 40px;
      }
    }
  }
}
