.free-shipping-in-line {
  border-radius: 0.3rem;
  display: flex;
  max-width: fit-content;
  padding: 0 0.5rem;
  margin-bottom: 0.5rem;
  align-items: center;

  span {
    line-height: 1.2;
    margin-top: 0.1rem;
    font-size: 0.9rem;
    font-weight: 500;
  }
}
