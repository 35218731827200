.size-list, .color-list {
  .spec-item.active button {
    user-select: none;
    border-color: #2caf2c;
    box-shadow: inset 0 0 30px -20px #2caf2c !important;
  }
}

.size-list {
  .spec-item {
    button {
      line-height: 1;
      border-radius: 5px;
      font-weight: 800;
      padding: 10px;
      font-size: 13px;
      text-align: center;
      border: 1px solid #d4d4d4;

      &:hover {
        filter: brightness(.8);
      }
    }
  }
}

.color-list {
  .spec-item {
    &.disabled button {
      opacity: .5;
      border-color: #dbdbdb;
      box-shadow: inset 0 0 30px -20px #000 !important;
    }

    button {
      position: relative;
      padding: 5px;
      min-width: 40px;
      max-width: 95px;
      height: 100px;
      border-radius: 5px;
      margin-bottom: 5px;
      border: 1px solid #d4d4d4;

      div {
        display: flex;
        flex-direction: column;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;

        img {
          align-self: center;
          max-width: 100%;
          opacity: 1;
          width: 50px;
          height: 50px;
          margin: 0 8px;
        }

        span {
          display: inline-block;
          color: #666;
          line-height: 1;
          width: 100%;
          text-align: center;
          -webkit-box-align: center;
          align-items: center;
          max-width: 100%;
          font-size: 11px;
          margin-top: 5px;
          // white-space: nowrap;
          // overflow: hidden;
          // text-overflow: ellipsis;
        }
      }
    }
  }
}
