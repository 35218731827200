.bg-color-light {
  color: rgb(70, 70, 70);

  &.active {
    font-weight: bold;
    background-color: #d1d1d1;
  }
}

.bg-color-dark {
  color: rgb(255, 255, 255);

  &.active {
    font-weight: bold;
    background-color: rgba(209, 209, 209, 0.32);
  }
}

.filter-title {
  padding: 15px 0px;
  font-size: 20px;
  font-weight: bold;

  border-bottom: 1px solid rgba(56, 56, 56, 0.15);
}

.filter-subtitle {
  font-weight: 700;
  font-size: 16px;
  display: block;
  padding: 10px 0px;
}

.item-filter,
.filtered {
  font-size: 14px;
  text-transform: capitalize;
  display: flex;
  width: fit-content;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  &.active {
    border-radius: 10px;
  }
}

.price-shortcut {
  @extend .item-filter;
  text-transform: none;
}

.filter-section {
  border-bottom: 1px solid rgba(56, 56, 56, 0.15);
}

.price-filter {
  flex-wrap: unset;

  .submit-price {
    border-radius: 50%;
    cursor: pointer;
    margin: 0 0 0 2%;
  }
}

.collapse-icon {
  cursor: pointer;
}

.aplied-filters {
  span {
    &:not(:last-child)::after {
      content: ' | ';
      padding: 0 2px;
    }
  }
}

.link {
  @extend .item-filter;
  color: #0093ed;
  float: right;
}

@media (min-width: 768px) {
  .price-filter {
    flex-wrap: wrap;

    .submit-price {
      border-radius: 50%;
      cursor: pointer;
      margin: 3% 0 0 0;
    }
  }
}

@media (min-width: 1300px) {
  .price-filter {
    flex-wrap: unset;

    .submit-price {
      border-radius: 50%;
      cursor: pointer;
      margin: 0 0 0 2%;
    }
  }
}
