.booklet-card {
  position: relative;
  border-radius: 10px;
  border: 1px solid #3333331a;
  background-color: #fff;
  box-shadow: 3px 2px 4px -3px #646464;

  &:hover {
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.16), 0 -1px 2px 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }

  .image-wrapper {
    padding: 5px;
    width: 100%;
    margin: 0 auto;

    .image {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }
}

.section-title {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1rem;
  border-bottom: 1px solid #b5b5b5;
}

.booklets-section {
  padding: 2rem;
}
