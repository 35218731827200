@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/_variables';

.product-carousel-item {
  margin: 0.75rem 0.75rem;
}

.product-card {
  position: relative;
  border-radius: 10px;
  border: 1px solid #3333331a;
  background-color: #fff;
  box-shadow: 3px 2px 4px -3px #646464;

  &:hover {
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.16), 0 -1px 2px 0 rgba(0, 0, 0, 0.1);
  }

  .image-wrapper {
    padding: 5px;
    width: 100%;
    margin: 0 auto;

    .image {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }

  .brand-badge {
    background-color: #138293;
    color: #fff;
  }

  .info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-height: calc(100px + 125px);
    padding: 5px 10px;

    background-color: #fff;
    border-top: 1px solid #3333331a;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    .title {
      overflow: hidden;
      margin-bottom: 0.25rem;

      p {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-height: 1.1;
        text-align: left;
        font-size: 10pt;
        margin-bottom: 0;
        color: rgb(63, 63, 63);
      }
    }

    .description {
      display: block;
      overflow: hidden;

      p {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        word-wrap: break-word;
        max-height: 29px;
        overflow: hidden;
        text-transform: lowercase;
        line-height: 1.1;
        margin: 0;
        font-weight: 300;
        font-size: 10.5pt;
        color: #666;
      }
    }
  }
}

.products-list {
  display: flex;
  overflow-x: auto;
  flex-direction: row;
  white-space: nowrap;
  padding-inline-start: 0;
  list-style: none;
  padding: 5px 0;

  .product-card {
    margin-right: 8px;

    .product-wrapper {
      width: 150px;
    }

    &:first-child {
      margin-left: 0;
    }

    .info {
      .title p {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .description p {
        display: block;
      }
    }
  }
}

.product-one-line-card {
  position: relative;
  background-color: #fff;
  border: 1px solid #3333331a;

  &:hover {
    filter: brightness(0.95);
  }

  .image-wrapper {
    height: 100px;
  }

  .title {
    color: #000;
    font-size: 12pt;
  }

  .brand {
    color: #0093ed;
  }

  .brand,
  .description {
    font-size: 10pt;
  }
}

.reward-promotion-wrapper {
  position: absolute;
  top: 45px;
  z-index: 1;
  left: -32px;
  width: 100px;
  height: 50px;
  flex-wrap: wrap;
  box-sizing: border-box;
  transform: rotate(-90deg);
  border-radius: 0px 4px 4px;
  background: #00652e;

  .tag {
    position: relative;
    display: flex;
    height: auto;
    box-shadow: none;
    flex-direction: column;
    justify-content: center;

    font-size: 12px;
    text-align: left;
    font-weight: 500;
    line-height: 14px;
    margin: 5px 10px;
    color: #fff;

    &::before {
      content: '';
      position: absolute;
      left: 4px;
      bottom: 24px;
      z-index: -3;
      width: 4px;
      height: 100%;
      background: #00652e;
      transform: rotate(-90deg);
    }

    &::after {
      content: '';
      position: absolute;
      top: -5px;
      left: -21px;
      width: 12px;
      height: 8px;
      background-color: #000;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }
}

@media (min-width: 768px) {
  .product-card .info .title p {
    font-size: 12pt;
  }

  .product-one-line-card {
    .title {
      font-size: 20px;
    }

    .brand,
    .description {
      font-size: 16px;
    }
  }
}

@media (min-width: 992px) {
  .product-card .info {
    min-height: calc(100px + 115px);
  }
}
