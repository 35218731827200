@import '../../../../scss/customScrollbar.scss';

.payment-tabs {
  &.card-header-tabs {
    margin: 0;
  }
  .nav-link {
    color: gray;
    border: none;
    border-bottom: 1px solid #dee2e6;
    &.active,
    &.active:hover {
      color: #333;
      border: none;
      border-bottom: 2px solid #333;
    }
    &:hover {
      border: none;
      border-bottom: 1px solid #dee2e6;
    }
  }
}

.payment-tab {
  @extend .custom-scrollbar;

  overflow-y: scroll;
  max-height: 400px;
}

.payment-availability {
  margin: 0.25rem 0;
  font-size: 0.75rem;
  color: #d40000;
}

.payment-method {
  &:not(:last-child) {
    border-bottom: 1px solid #ccc;
    padding-bottom: 0.5rem;
  }
}

.plan-info {
  color: #fff;
  background-color: #28a745;
  cursor: default !important;

  &:hover {
    transition: none;
    color: #fff;
  }
}
