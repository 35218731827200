$secondary: #989898;
$active: #17a2b8;

.statuses-progress {
  margin-bottom: 0;
  display: flex;
  flex-direction: column;

  .status {
    width: 190px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    z-index: 1;
    margin: .5rem auto;
    font-size: 12pt;
    color: $secondary;

    .name {
      line-height: 1;
      font-size: 12pt;
      margin-left: .5rem;
    }

    .tag {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      z-index: 2;
      padding: .75rem;
      border-radius: 50%;
      background-color: #fff;
      border: 2px solid $secondary;
    }

    &::before {
      width: 35px;
      height: 2px;
      content: '';
      position: absolute;
        top: 50px;
        left: 6px;
        z-index: -1;
      transform: rotate(90deg);
      background-color: $secondary;
    }

    &:last-child::before {
      content: none;
    }

    &.active {
      color: $active;

      & .tag {
        border-color: $active;
      }

      & + .status::before {
        border-color: $active;
      }
    }
  }
}

@media (min-width: 768px) {
  .statuses-progress {
    flex-direction: row;
    justify-content: center;

    .status {
      width: 130px;
      margin: .5rem .25rem;
      flex-direction: column;

      &::before {
        top: 25px;
        left: 50px;
        width: 100%;
        margin: auto 0;
        transform: rotate(0deg);
      }

      .name {
        text-align: center;
        margin-top: 0.25rem;
      }

      .tag svg {
        width: 25px;
        height: 25px;
      }
    }
  }
}
