#waiting-seller-modal {
  animation: fadeIn 0.8s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;

  .modal-content {
    border-radius: 20px;

    .modal-body {
      svg {
        animation: zoom 2s infinite;
      }

      .alert-body {
        .title {
          font-family: 'Roboto', Arial, sans-serif;
          font-weight: 600;
          font-size: 24px;
          color: #535353;
          margin: 1rem;
          word-break: break-word;
        }

        .subtitle {
          font-family: 'Roboto', Arial, sans-serif;
          font-weight: 400;
          font-size: 16px;
          color: #535353;
          padding: 0 1rem;
          margin-bottom: 1rem;
          word-break: break-word;
          text-align: center;
        }
      }

      .alert-footer {
        display: flex;
        justify-content: space-between;
        padding: 0.5rem 0;
      }
    }
  }
}
