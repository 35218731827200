.mini-images {
  display: flex;
  overflow: auto;
  white-space: nowrap;
  align-items: center;
  flex-direction: row;
  margin: 5px 0;
  max-height: 300px;

  .mini-images-item {
    display: inline-table;
    cursor: pointer;
    width: 50px;
    height: 50px;
    overflow: hidden;
    margin: 5px 2.5px;
    border-radius: 4px;
    border: 1px solid #c7c7c7;
    background-color: #fff;

    &:first-of-type {
      margin-left: 0;
    }

    &.active {
      opacity: .5;
    }
  }
}

@media (min-width: 768px) {
  .mini-images .mini-images-item {
    margin: 2.5px 5px;

    &:first-of-type {
      margin-left: 5px;
    }
  }
}
