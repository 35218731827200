/// Mixin to iterate over values and generate classes with specified properties.
///
/// @param {String} $baseClass - The base class name for generated classes.
/// @param {List} $sizes - A list of values to iterate over.
/// @param {List} $properties - A list of properties to apply to the generated classes.
@mixin generateClassForEachValue($baseClass, $sizes, $properties) {
  @for $i from 1 through length($sizes) {
    $key: $i - 1;
    $value: nth($sizes, $i); // get the value from the list

    .#{$baseClass}-#{$key} {
      @each $property in $properties {
        #{$property}: $value;
      }
    }
  }
}
