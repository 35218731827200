@import '~bootstrap/scss/bootstrap.scss';
@import './resources/scss/gap.scss';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  text-decoration: none !important;
}

section {
  .section-header {
    font-size: 2rem;
    font-weight: 700;
    margin-top: 3rem;
    margin-bottom: 2rem;
    border-bottom: 1px solid #b5b5b5;
  }
}

.form-control {
  &:focus {
    box-shadow: none !important;
  }
}

button {
  &:focus {
    box-shadow: none !important;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

main {
  min-height: calc(100vh - 119px);
}

.Search {
  min-height: calc(100vh - 119px);
}

.search-icon {
  min-height: 75vh;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.1;
}

// Toast changes
.Toastify__toast {
  min-height: 30px;

  .Toastify__toast-body {
    margin: 0;
    padding: 0;
  }
}

.skeleton-load {
  height: 15px;
  animation: shine 2s infinite;

  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 80%
    ),
    lightgray;
  background-position: 0;
  background-repeat: repeat-y;
  background-size: 50px 500px;

  &.text {
    height: 20px;
    margin-bottom: 0.25rem;
    border-radius: 10px;
  }
}

.modal-rounded {
  .modal-content {
    border-radius: 20px;
    overflow: hidden;
  }
}

@media (min-width: 1700px) {
  .breakpoint-page {
    max-width: 1600px;
  }

  .banner-breakpoint {
    max-width: 1700px;
  }
}

@keyframes shine {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 100% 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes zoom {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.cursor-pointer {
  cursor: pointer;
}

.resize-none {
  resize: none;
}

.user-select-none {
  user-select: none;
}

code {
  color: inherit;
}

.load-gray {
  padding: 1rem;

  .circle {
    background-color: #494949 !important;
  }
}

.product-load-image {
  background-image: url(./resources/assets/images/product-load.svg);
  background-size: contain;
}
