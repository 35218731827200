@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/_variables';

.prices-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .price.has-reward {
    width: 100%;
    height: 100%;
    align-self: end;
  }

  .reward-price {
    .title {
      font-size: 9pt;
    }

    .tag,
    .value {
      font-size: 15pt;
      font-weight: 700;
    }

    .tag {
      font-size: 9pt;
      font-weight: 400;
    }
  }
}

.price {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  height: 100%;
  align-self: end;

  .updated-price {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    justify-content: flex-end;
    line-height: 1;

    .tag,
    .value {
      font-size: 12pt;
      font-weight: 700;
      color: #333;
    }

    .tag {
      font-size: 9pt;
      white-space: pre;
      font-weight: 400;
    }
  }

  .old-price {
    display: block;
    text-decoration: line-through;
    color: rgb(102, 102, 102);
    font-size: 12px;

    .tag,
    .value {
      font-size: 9pt;
      font-weight: 400;
    }

    .discount {
      font-size: 7pt;
    }
  }
}

@include media-breakpoint-up(xl) {
  .prices-wrapper {
    flex-direction: row;

    .reward-price {
      width: 50% !important;

      .title {
        font-size: 8pt;
      }

      .value {
        font-size: 18pt;
      }
    }
  }

  .price {
    &.has-reward {
      width: 50% !important;
      flex-direction: column;
      align-items: flex-end !important;

      .old-price {
        margin-right: 0 !important;
      }
    }

    .old-price {
      .value {
        font-size: 10pt;
      }

      .discount {
        font-size: 8pt;
      }
    }

    .updated-price .value {
      font-size: 18pt;
    }
  }
}
