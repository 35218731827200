.ordination-wrapper {
  select {
    padding: 0;
    height: auto;
    border: none;
    background-color: transparent;

    &:focus {
      background-color: transparent;
    }

    &:focus-visible {
      outline: 0;
    }
  }
}
