@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/_variables';

.breadcrumb {
  background-color: transparent;
  min-height: unset;
  margin-bottom: unset;
}

.navbar-nav {
  display: inline-flex;
  max-width: 100%;

  .breadcrumb-item {
    display: inline-block;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.category {
      max-width: 12ch;
    }
  }

  a,
  span {
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    line-height: 1;

    &:not(:first-child)::before {
      float: left;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      content: '>';
    }

    &:not(:last-child) {
      min-width: fit-content;
    }
  }

  span {
    user-select: none;
  }

  a:hover {
    opacity: 0.8;
  }
}

@media (min-width: 768px) {
  .navbar-nav {
    .breadcrumb-item {
      &.category {
        max-width: 100%;
      }
    }
  }
}
