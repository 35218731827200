.options-list {
  line-height: 1;
  list-style-type: disc;
  padding-inline-start: 0;
  margin-bottom: 0;

  li {
    display: inline-flex;
    font-size: 9pt;
    color: #2c2c2c;

    &:not(:last-child)::after {
      content: '\2022';
      padding: 0 2px;
    }
  }
}
