.social-images {
  max-width: 23px;
  max-height: 23px;
  width: auto;
  height: auto;

  object-fit: contain;
}

.shop-infos {
  span:not(:last-child) {
    &::after {
      content: ' | ';
    }
  }
}
