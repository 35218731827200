.brand-filter-list {
  display: flex;
  flex-wrap: wrap;

  li {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    line-height: 1;
    font-weight: 400;
    font-size: 0.9rem;

    padding: 5px 10px;
    margin-bottom: 0.25rem;
    border-radius: 30px;
    background-color: #d1d1d1;

    &.active {
      color: #fff;
      background-color: #28a745;
    }

    &:hover {
      filter: brightness(0.9);
    }
  }
}

.show-more-item {
  text-decoration: underline;
  color: #2f4c9e;
  background-color: transparent !important;
}
