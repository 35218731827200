.ask-zipcode {
  width: 300px;
  box-sizing: border-box;
  height: auto;
  position: absolute;
  z-index: 9;
  background-color: rgb(255, 255, 255);
  margin-top: 30px;
  border-radius: 16px;
  pointer-events: initial;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 0px 15px;
  top: 45px;
  left: 5px;
  transform: translateY(-2em);
  transition: all 0.3s ease 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;

  &:hover {
    transition-delay: 0s, 0s, 0.3s;
  }

  &::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    bottom: 100%;
    left: 1.5em;
    border: 0.75rem solid transparent;
    border-top: none;
    border-bottom-color: #fff;
    filter: drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, 0.1));
  }

  .zipcode-content {
    position: relative;
    min-height: 160px;
    padding: 20px 25px 30px;
    position: relative;
    justify-content: center;

    -webkit-box-pack: center;

    .title-zipcode {
      color: rgb(66, 66, 66);
      font-size: 16px;
      margin-bottom: 15px;
    }

    .subtitle-zipcode {
      @extend .title-zipcode;
      font-size: 12px;
      color: rgb(66, 66, 66);
    }

    .zipcode-btn {
      font-size: 14px;

      &:hover {
        filter: brightness(0.9);
      }
    }
  }
}

.background-overlay {
  &.active {
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    left: 0px;
    right: 0px;
    top: 0px;
    height: 100vh;
    z-index: 8;
    transition: all 0.3s ease 0s;
    cursor: default;
    visibility: visible;
    opacity: 1;
  }
}

@media (min-width: 768px) {
  .ask-zipcode {
    right: 0;
    left: unset;
    top: 59px;

    &::before {
      left: 14.5rem;
    }
  }
}

@media (min-width: 1626px) {
  .ask-zipcode {
    right: 0;
    left: unset;
    top: 45px;

    &::before {
      left: 1.5em;
    }
  }
}
