.custom-scrollbar {
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #bdbdbd;
    border-radius: 5px;

    &:hover {
      background: #a1a1a1;
    }
  }
}
