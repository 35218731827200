.availability {
  margin-top: 0.25rem;
  font-size: 0.75rem;
  color: #d40000;
}

.cart-item-card {
  .title {
    color: #000;
  }
}

.medicine-strip {
  background: #fb3737 0% 0% no-repeat padding-box;
  border-radius: 4px;
  font-size: 12px;
  color: #fff;
  font-weight: 600;
  padding: 0 0.25rem;
  width: fit-content;
}
