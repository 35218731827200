@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/_variables';

.navbar {
  min-height: 100px;
}

.cart-counter {
  position: absolute;
  top: -4px;
  right: -8px;

  width: 18px;
  height: 18px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 10pt;
  border-radius: 40px;
  color: #fff;
  background-color: #dc3545;
}

.navbar-categories-list {
  overflow-x: auto;
  overflow-y: hidden;
  flex-wrap: wrap;
  justify-content: start;
  align-items: baseline;

  .navbar-category-link {
    display: flex;
    align-items: center;
    margin: 0.5rem 2.5rem 0 0;
    line-height: 1;
    font-size: 15px;
    letter-spacing: 0.015rem;
    font-weight: 600;

    &:hover {
      text-decoration: underline;
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &.all-categories {
      cursor: pointer;
      font-size: 12pt;
      font-weight: 400;

      &:hover {
        text-decoration: none;
      }
    }

    .category-link-ellipses {
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;

      p {
        margin: 0;
        display: -webkit-box;
        width: 150px;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        line-height: 1.1;
      }
    }
  }
}

.all-categories-link {
  cursor: pointer;
  user-select: none;
  font-size: 12pt;
  font-weight: 700;
}

.all-categories-dropdown-menu {
  padding: 0;

  .dropdown-item-navbar {
    padding: 0.1rem 0.75rem;

    &:active {
      background-color: #c4c4c4;
    }
  }
}

.search-input {
  pointer-events: none;
}

.navbar-information {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0.5rem 0;
  margin: 0;
}

@media (min-width: 768px) {
  .all-categories-link {
    min-width: 185px;
    font-size: 14pt;
    margin: 0.1rem 2rem 0 0 !important;
  }

  .navbar-categories-list .navbar-category-link {
    margin: 0.1rem 2.5rem 0 0;
  }
}
