.whole-sales {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 10pt;
  line-height: 1;
  text-align: center;
  white-space: pre-wrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
