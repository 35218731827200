.delivery-information {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5rem 0;

  .method,
  .infos {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }

  .title {
    font-size: 1rem;
    color: #222222;
    font-weight: 600;
  }

  .subtitle {
    font-size: 0.8rem;
    color: #4b4b4b;

    &.action {
      cursor: pointer;
      text-decoration: underline;
      color: #1e90ff;
    }
  }
}

.without-method {
  display: flex;
  justify-content: center;
  padding-top: 0.5rem;

  .warning-text {
    color: #4b4b4b;
    font-size: 0.9rem;
    font-weight: 500;
  }
}

.delivery-methods-modal {
  .modal-content {
    border-radius: 1rem;

    .modal-title {
      font-size: 1.25rem;
      font-weight: 500;
      color: #444444;
    }

    .infos {
      .address {
        color: #696969;
        font-size: 0.9rem;
        word-break: break-word;
      }
    }

    .delivery-list {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding: 0 0.5rem 0.5rem 0.5rem;

      .delivery-method-card {
        display: flex;
        flex-direction: row;
        column-gap: 1rem;
        align-items: center;
        border-radius: 0.5rem;
        border: #e0e0e0 1px solid;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        padding: 0.4rem;
        cursor: pointer;

        .card-wrapper {
          display: flex;
          justify-content: space-between;
          width: 100%;

          .info {
            display: flex;
            flex-direction: row;
            column-gap: 0.5rem;
            align-items: center;
          }

          .value {
            display: flex;
            align-items: center;
            min-width: fit-content;
          }
        }

        .title {
          color: #000;
          font-size: 1rem;
          font-weight: 500;
        }

        .description {
          color: #696969;
          font-size: 0.9rem;
          display: flex;
          align-self: flex-end;
        }
      }
    }
  }
}

.load-delivery {
  padding: 1rem;

  .circle {
    background-color: #494949;
  }
}

.unavailable-free-shipping-btn {
  display: block;
  background-color: transparent;
  border: 1px solid #0088ff;
  border-radius: 0.3rem;
  color: #0088ff;
  cursor: pointer;
  font-size: 0.8rem;
  text-align: center;
  width: 100%;
  text-decoration: underline;

  &:hover {
    color: #0075db;
    text-decoration: underline;
    filter: brightness(0.9);
  }
}

#shipping-discount-modal {
  .modal-content {
    .modal-body {
      background-color: #f2f2f2;
    }
  }
}

.discount-card {
  background-color: #fff;
  border: 1px solid #e6e6e6;

  .discount-card-body {
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
    width: 100%;

    .discount-icon {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 2rem;
      border-radius: 2rem;
      min-width: 4rem;
      height: 4rem;

      &.free-shipping {
        background-color: #43a3ff;
      }

      &.price-shipping {
        background-color: #20bc55;
      }

      .discount-badge {
        position: absolute;
        bottom: -0.4rem;
        font-size: 0.7rem;
        z-index: 1;
        border-radius: 1rem;

        &.free-badge {
          background-color: #005eaf;
          color: #fff;
        }

        &.price-badge {
          background-color: #09883b;
          color: #fff;
        }
      }
    }

    .discount-info {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      .discount-info-description {
        font-size: 0.8rem;
        color: #686868;
      }
    }
  }
}
