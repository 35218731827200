@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/_variables';

.cart-item-card {
  cursor: pointer;
  margin: 0.25rem 0;
  padding: 10px;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #3333331a;
  box-shadow: 2px 2px 5px -4px black;

  &:hover {
    filter: brightness(0.9);
  }

  .image-wrapper {
    position: relative;
    height: 95px;

    .quantity {
      position: absolute;
      right: 0;
      bottom: 0;
      padding: 0.25rem;
      font-size: 8pt;
      font-weight: 700;
    }
  }

  .title {
    font-size: 11pt;
    margin-bottom: 0;
  }

  .price {
    line-height: 1;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: column;

    .old-price .tag,
    .old-price .value {
      font-size: 9pt;
    }

    .updated-price {
      flex-wrap: initial;
      margin-left: auto;

      .value {
        font-size: 14pt;
      }
    }

    .tag {
      margin-right: .25rem;
    }
  }
}

.cart-resume {
  border-radius: 0;
  border-radius: 11px;

  .card-body {
    padding: 0.25rem;

    .add-more-items {
      line-height: 1;
      font-size: 10pt;
    }

    .discount,
    .delivery-rate {
      font-weight: 400;
      color: #777;
    }

    .total,
    .discount,
    .delivery-rate {
      line-height: 1.2;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 11pt;
      margin-bottom: 0;
    }

    .total {
      color: #333;
      font-size: 13pt;
    }

    .total-price {
      color: #333;
      font-size: 16pt;
    }
  }
}

#change-item-modal {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;

  .modal-content {
    border: none;
    border-radius: 0;
  }

  .modal-body {
    .info-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0.5rem;

      .title {
        color: #333;
        margin-bottom: 0;
        text-align: center;
        font-size: 14pt;
        line-height: 1.1;
        text-decoration: underline;
      }
    }

    .description {
      margin-bottom: 0;
      display: -webkit-box;
      overflow: hidden;
      line-height: 1;
      font-size: 10pt;
      white-space: pre-wrap;
      -webkit-line-clamp: 8;
      -webkit-box-orient: vertical;
      color: #333;
    }
  }

  .modal-footer {
    padding: 0;
    border-top: none;

    .change-quantity-form {
      width: 100%;
      display: flex;
      flex-direction: column;

      .top {
        margin-bottom: 0.25rem;
        button {
          padding: 2px 10px;
        }
      }

      .bottom {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  #change-item-modal {
    margin: 1.75rem auto;

    .modal-content {
      border-radius: .3rem;
    }
  }

  .price {
    .old-price .tag,
    .old-price .value {
      font-size: 10pt;
    }

    .updated-price {
      .value {
        font-size: 18pt;
      }
    }
  }
}
