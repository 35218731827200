@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/_variables';

.ProductDetail {
  font-family: Roboto, sans-serif;
  font-display: swap;

  .title {
    font-size: 1.75rem;
    line-height: 1.09;
    color: #707070;
  }

  .wrapper-card {
    box-shadow: 0 0 8px -3px #757575;

    .card-body {
      .description {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 7;
        white-space: pre-wrap;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
      }

      .price-wrapper {
        display: flex;
        align-items: self-end;
        flex-direction: column;
        justify-content: space-between;

        .price {
          display: flex;
          align-items: flex-end;
          white-space: pre-wrap;

          .price-and-discount {
            width: 100%;
            justify-content: flex-end !important;

            .old-price {
              display: inherit;
              justify-content: flex-start;

              .tag {
                font-size: 11pt;
              }

              .value {
                font-size: 12pt;
              }

              .discount {
                display: block !important;
                margin-left: auto;
              }
            }
          }

          .updated-price {
            width: 100%;
            display: inherit;
            margin-left: 0.5rem;

            .value {
              font-size: 25pt;
            }
          }
        }

        .sale-btn {
          font-size: 18pt;
        }
      }
    }
  }

  .add-to-cart {
    .quantity {
      width: 90px;
      display: block;
      height: 50px;
      font-size: 1.313rem;
      font-weight: 700;
      text-align: center;
      padding: 0 5px;
      border-radius: 5pt;
      background: #fff;
      color: #707070;
      border-color: #e4e4e4;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 39px;
      border-radius: 4px;
      border-color: #007cc0;
      background-color: #007cc0;

      &:hover {
        border-color: #007cc0;
      }

      &:focus {
        box-shadow: none;
      }
    }
  }

  .medicine-warning {
    @extend .add-to-cart;

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 39px;
    border-radius: 4px;
    background-color: #bebebe;
    color: #494949;
  }
}

@include media-breakpoint-up(md) {
  .ProductDetail {
    .mini-images {
      flex-direction: column;
    }

    .add-to-cart .quantity {
      width: 80px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .ProductDetail {
    .add-to-cart .quantity {
      width: 120px;
    }
  }
}

.black-stripe-warning {
  .title {
    font-size: 1rem;
    line-height: 1.09;
    color: #000;
    margin-bottom: 1rem;
  }

  display: flex;
  flex-direction: column;
  background: #ffe08249 0% 0% no-repeat padding-box;
  border-radius: 4px;
  font-size: 0.8rem;
  border-left: 3px solid #ffc107;
  color: #666666;
  padding: 0.5rem;
}
