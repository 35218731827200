@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/_variables';

$secondary: #6c757d;

.shipment-card {
  position: relative;
  margin: 1.2rem 0;

  &:last-of-type {
    margin-bottom: 0;
  }

  &.disabled {
    opacity: 0.65;
    background-color: #bcbcbc;

    .card-header,
    .card-body .left,
    .card-body .btn-shipment {
      color: #fff !important;
      border-color: #898989 !important;
    }

    .card-header,
    .card-body .btn-shipment {
      background-color: #898989 !important;
    }

    .btn-shipment {
      cursor: not-allowed;
    }
  }

  &.paid {
    opacity: 0.85;
    background-color: #f5fff7;

    .card-body,
    .left,
    .card-body,
    .card-footer {
      color: #004e12;
      border-color: #28a745 !important;
    }

    .card-body {
      background-color: #f5fff7 !important;
    }

    .btn-shipment {
      cursor: not-allowed;
    }
  }

  .paid-alert-header {
    background-color: #28a745;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    column-gap: 0.5rem;
    padding: 0.3rem;
  }

  .card-header {
    position: absolute;
    left: -8px;
    top: -13px;
    z-index: 1;
    line-height: 1;
    padding: 1px 6px;
    border-radius: 0.25rem;
  }

  .card-body {
    padding: 0.75rem 0.5rem 0.5rem;

    .wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .left {
        display: inherit;
        flex-direction: row;

        .avatar {
          img {
            max-width: 2.5rem;
            border-radius: 2rem;
          }

          .icon {
            color: $secondary;
            font-size: 2.5rem;
          }
        }

        .info {
          margin: auto 0 auto 0.5rem;

          h6 {
            margin-bottom: 0;
          }
        }

        .shipment-address h6 {
          font-size: 10pt;
        }
      }

      .btn-shipment {
        line-height: 1;
        padding: 2px 6px;
        border-radius: 1rem;

        &:active,
        &:hover,
        &:focus-visible {
          outline: 0;
          filter: brightness(0.9);
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .shipment-card .card-body .left .show-address h6 {
    font-size: 12pt;
  }
}
