#ask-cpf-modal {
  .modal-content {
    padding: 0.5rem;

    .modal-header {
      padding: 0 1rem;
    }

    .modal-body {
      padding: 0.5rem;
      text-align: center;
      font-size: 1rem;
      color: #333;

      .ask-cpf-title {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .ask-cpf-body {
        .ask-cpf-question {
          font-family: 'Roboto', Arial, sans-serif;
          font-weight: 600;
          font-size: 18px;
          color: #535353;
          margin: 1rem;
          word-break: break-word;
        }

        .ask-cpf-description {
          font-family: 'Roboto', Arial, sans-serif;
          font-weight: 400;
          font-size: 16px;
          color: #535353;
          margin: 1rem;
          word-break: break-word;
        }

        .ask-cpf-input {
          width: 100%;
          height: fit-content;
          position: relative;
          display: flex;
          flex-direction: column;
          gap: 5px;

          .input-icon {
            width: 20px;
            position: absolute;
            z-index: 99;
            left: 12px;
            bottom: 9px;
          }

          .cpf-field {
            width: auto;
            height: 40px;
            padding: 0 0 0 40px;
            border-radius: 7px;
            outline: none;
            border: 1px solid #e5e5e5;
            filter: drop-shadow(0px 1px 0px #efefef)
              drop-shadow(0px 1px 0.5px rgba(239, 239, 239, 0.5));
            transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);

            &.is-invalid {
              border: 1px solid #ff0000;
            }

            &:focus {
              border: 1px solid transparent;
              box-shadow: 0px 0px 0px 2px #646464;
              background-color: transparent;
            }
          }
        }

        .ask-cpf-footer {
          padding-top: 1.2rem;
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
}
