.description-wrapper {
  display: block;
  overflow: hidden;

  .description-text {
    line-height: 1.1;
    text-align: left;
    font-size: 12pt;
    margin-bottom: 0;
    color: #666;
  }
}
