.skeleton-circle-load {
  display: flex;
  align-items: center;
  justify-content: center;

  &.fullscreen {
    height: 100vh;
  }

  .circle {
    display: inline-block;
    width: 15px;
    height: 15px;
    margin: 0 .125rem;
    border-radius: 15px;
    background-color: #4b9cdb;

    &:nth-last-child(1) {
      animation: loadingC .6s .1s linear infinite;
    }

    &:nth-last-child(2) {
      animation: loadingC .6s .2s linear infinite;
    }
    &:nth-last-child(3) {
      animation: loadingC .6s .3s linear infinite;
    }
  }
}

@keyframes loadingC {
  0% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(0, 15px);
  }
  100% {
    transform: translate(0, 0);
  }
}
