.btn-list-store {
  background: transparent;
  border: none;
}

.btn-list-address {
  background: transparent;
  border: none;
}

.btn-method {
  color: #212529;
  background-color: #e0e0e0;
  border-color: #e0e0e0;
  min-height: 60px;
  font-size: medium;
  font-weight: 500;
  &:hover {
    background-color: #b6b6b6;
  }
}

.btn-list {
  &:hover {
    background-color: #b6b6b6;
  }
}

.address-wrapper {
  .full-address {
    font-size: 14px;
    word-break: break-word;
  }
}

.address-container {
  display: flex;
  height: 100%;
  width: 100%;
}

.btn-list-address {
  .address-container {
    padding: 1rem 0;
  }
}

.btn-list-store {
  .address-container {
    padding: 0.2rem 0;
  }
}
