@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/_variables';

.category-distinction {
  position: relative;

  .name {
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    line-height: 1;
    font-size: 15pt;
    text-align: center;
    padding: 0.5rem 0.25rem;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.658);
  }
}
