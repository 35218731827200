.privacy-policy-popup {
  display: flex;
  position: fixed;
  align-items: center;
  margin: 0px auto;
  background: rgb(255, 255, 255);
  max-width: 100%;
  padding: 1.5rem;
  border-radius: 0.75rem;
  left: 10px;
  right: 10px;
  bottom: 25px;
  z-index: 999;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  -webkit-box-align: center;
  animation: fadeIn 0.8s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;

  &.hidden {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }
}

.popup-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

@media (min-width: 768px) {
  .privacy-policy-popup {
    max-width: 80%;
  }
}

@media (min-width: 992px) {
  .privacy-policy-popup {
    max-width: 60%;
  }
}
